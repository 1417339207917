<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Newsletter Subscribers List</h4>
          <div class="d-flex justify-content-start">
            <div class="text-center pt-1 px-1">
<!--              <a v-if="$store.getters['auth/checkAccess']('newsletter/send-email')"-->
<!--                 class="btn btn-info btn-wd export_excel" style="display:flex;"-->
<!--                 @click="openEmailTemplateModal()"> Send email to selected subscribers-->
<!--                <span class="btn-label px-2">-->
<!--                  <i class="nc-icon nc-cloud-download-93" style="font-size:20px;"></i>-->
<!--                  </span>-->
<!--              </a>-->
<!--              <a v-if="$store.getters['auth/checkAccess']('newsletter/export')"-->
<!--                 class="btn btn-info btn-wd export_excel" style="display:flex;"-->
<!--                 @click="openModal('basic')"> Export to excel-->
<!--                <span class="btn-label px-2">-->
<!--                  <i class="nc-icon nc-cloud-download-93" style="font-size:20px;"></i>-->
<!--                  </span>-->
<!--              </a>-->
              <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/index'])"
                           :to="'/email-templates/list'"
                           class="btn btn-success btn-wd">
                Show templates
                <span class="btn-label">
                      <i class="fa fa-eye"></i>
                  </span>
              </router-link>
              <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-campaigns/index'])"
                           :to="'/email-campaigns/list'"
                           class="btn btn-info btn-wd ml-2">
                Create Campaign
                <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
              </router-link>
            </div>
          </div>
        </div>


        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select List"
              :input-classes="'select-default'"
              :list="listsList"
              :listItemLabel="'name'"
              :listItemValue="'id'"
              v-model="list_id">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->
        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :searchable="false"
            :api-url="'newsletter/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/checkAccess']('newsletter/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.email_address)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Subscriber?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <export-modal id="export-modal" title="News Letter" url="newsletter/export"
                    :filters="exportFilters" :modals="modals"></export-modal>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import FgSelect from "@/components/Inputs/formGroupSelect";

export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Email', value: 'email_address', minWidth: '200', align: 'center'},
        {label: 'Status', value: 'status', minWidth: '200', align: 'center'},
        {label: 'Date', value: 'timestamp_opt', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter: [
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-6',
          value: null,
        },
      ],
      exportFilters: [
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-6',
          value: null,
        },
      ],
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      listsList: [],
      list_id: '',
    }

  },
  mounted() {
    this.axios.post("newsletter/builder").then((response) => {
      this.listsList = response.data.lists;
      this.list_id = this.listsList[0].id
    }).catch((error) => {
      console.log(error)
      if (error.response.status === 400) {
        this.entityNotFoundError = true;
        this.$notify({
          message: "Failed to connect to mailchimp",
          timeout: 2000,
          type: 'danger'
        });

      } else {
        console.error(error);
      }
    })
  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'email': this.toDeleteId,
          'list_id': this.list_id
      }
      this.axios.delete("newsletter/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Subscriber deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    openEmailTemplateModal(){
      this.emailTemplateModalVisibility = true;
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.listsList) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>

